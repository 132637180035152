<template>
    <b-overlay :show='showLoader'>
        <b-card :title="$t('general.faq')">
            <b-row>
                <b-col>
                    <h3>Pri dodajanju nove naloge se izpiše opozorilo Polje Naslov je obvezno</h3>
                    <p>Naslov RN/IP je obvezen podatek. Naloge ne morete shraniti, če še niste vnesli naslova</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h3>Kako izberem področje?</h3>
                    <p>Področje izberete tako, da kliknete na spustni seznam in iz seznama izberete želeno področje. Če ni obkljukano interdisciplinarno, lahko dodate samo eno področje.
                        V nasprotnem primeru lahko za osnovno šolo izberete dva področja, za srednjo šolo pa tri.</p>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard} from 'bootstrap-vue'
    export default {
        components: {BCol, BRow, BOverlay, BCard},
        data() {
            return {
                showLoader: false
            }
        }
    }
</script>